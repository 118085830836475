import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { submitLoadAccountFundingSources } from '../../../../../redux/modules/funding-sources';
import {
  submitMoveMoney,
  resetMoveMoney,
} from '../../../../../redux/modules/move-money';
import SendMoneyPage from './SendMoneyPage';
import fundingSourceMapper from '../../../../../utils/funding-source-mapper';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  loading: state.fundingSources.loading,
  pending: state.moveMoney.pending,
  showAdminHeader: true,
  headingPreText: 'Payout to:',
  headingText: `${state.createCustomer.createdCustomer.firstName} ${state.createCustomer.createdCustomer.lastName}`,
  fundingSources: fundingSourceMapper(state.fundingSources.fundingSources),
  defaultDestinationUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  subStep: state.moveMoney.subStep,
});

const mapDispatchToProps = {
  submitLoadFundingSources: submitLoadAccountFundingSources,
  submitMoveMoney,
  resetMoveMoney,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendMoneyPage);
