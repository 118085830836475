import { connect } from 'react-redux';
import SendToFriendCompletePage from './SendToFriendCompletePage';
import { resetMoveMoney } from '../../../../../redux/modules/move-money';

const mapStateToProps = state => ({
  inviterFirstName: state.createCustomer.facilitatorCustomer.firstName,
  subStep: state.moveMoney.subStep,
});

const mapDispatchToProps = {
  resetMoveMoney,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(SendToFriendCompletePage);
