import React from 'react';
import { ProgressBar as BSProgressBar } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import StepIndicator from './StepIndicator';
import styles from './ProgressBar.module.scss';

const ProgressBar = ({ steps, currentStep, stepPercentage, variant }) => {
  const numberOfSteps = steps.length;
  const totalProgressPercent =
    ((currentStep - 1) / numberOfSteps) * 100 + stepPercentage / numberOfSteps;

  return (
    <div
      data-testid="progressBar"
      className={classNames(
        'bubble-progress-bar',
        styles.progressBarComponent,
        styles[variant]
      )}
    >
      <StepIndicator
        steps={steps}
        currentStep={currentStep}
        stepPercentage={stepPercentage}
        variant={variant}
      />
      <BSProgressBar className={variant} now={totalProgressPercent} />
    </div>
  );
};

ProgressBar.propTypes = {
  steps: PropTypes.arrayOf(PropTypes.string),
  currentStep: PropTypes.number,
  stepPercentage: PropTypes.number,
  variant: PropTypes.oneOf(['purple', 'gray']),
};

ProgressBar.defaultProps = {
  steps: ['Create Users', 'Attach Payment Accounts', 'Initiate Transfers'],
  currentStep: 0,
  stepPercentage: 0,
  variant: 'purple',
};

export default ProgressBar;
