import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import PageNav from '../../../../molecules/page-nav/PageNav';
import AttachBankAccountDashboard from '../../../../molecules/dashboard-view/AttachBankAccountDashboard';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import BankAttachedPayoutsScreen from '../../../phone-screens/2-attach-bank/BankAttachedPayoutsScreen';
import config from '../../../../../config';

const UnverifiedBankAttachedPage = ({
  theme,
  fundingSourceUrl,
  customerId,
  resetAttachBank,
  subStep,
}) => (
  <PhonePage
    theme={theme}
    stepPercentage={100}
    currentStep={2}
    currentSubStep="Bank Account Attached!"
    responseCodeComponent={
      <CreatedResponseCode resourceUrl={fundingSourceUrl} />
    }
    dashboardComponent={<AttachBankAccountDashboard customerId={customerId} />}
    pageNavComponent={
      <PageNav
        showReset={!!subStep}
        resetFunc={resetAttachBank}
        prevLink={config.tourFlow.attachBank.back}
        nextLink={config.tourFlow.attachBank.next}
      />
    }
  >
    <BankAttachedPayoutsScreen
      theme={theme}
      nextLink={config.tourFlow.attachBank.next}
    />
  </PhonePage>
);

UnverifiedBankAttachedPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  resetAttachBank: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

export default UnverifiedBankAttachedPage;
