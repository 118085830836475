import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import PageNav from '../../../../molecules/page-nav/PageNav';
import AttachBankAccountDashboard from '../../../../molecules/dashboard-view/AttachBankAccountDashboard';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import VerifyBankScreen from '../../../phone-screens/2-attach-bank/VerifyBankScreen';

const BankAttachedMicroDepositsPage = ({
  theme,
  stepPercentage,
  customerId,
  fundingSourceUrl,
  handleVerifyClicked,
  handleBackClicked,
  resetAttachBank,
  subStep,
}) => (
  <PhonePage
    theme={theme}
    stepPercentage={stepPercentage}
    currentStep={2}
    currentSubStep="Bank Account Attached!"
    responseCodeComponent={
      <CreatedResponseCode resourceUrl={fundingSourceUrl} />
    }
    dashboardComponent={<AttachBankAccountDashboard customerId={customerId} />}
    pageNavComponent={
      <PageNav
        showReset={!!subStep}
        resetFunc={resetAttachBank}
        prevOnClick={handleBackClicked}
        nextText="Next"
        nextOnClick={handleVerifyClicked}
      />
    }
  >
    <VerifyBankScreen theme={theme} handleVerifyClicked={handleVerifyClicked} />
  </PhonePage>
);

BankAttachedMicroDepositsPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number,
  customerId: PropTypes.string.isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  handleVerifyClicked: PropTypes.func.isRequired,
  handleBackClicked: PropTypes.func.isRequired,
  resetAttachBank: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

BankAttachedMicroDepositsPage.defaultProps = {
  stepPercentage: 50,
};

export default BankAttachedMicroDepositsPage;
