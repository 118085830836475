import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';
import { ProgressBar as BSProgressBar } from 'react-bootstrap';
import styles from './PageNav.module.scss';
import { ReactComponent as WhiteTextLogo } from '../../../assets/images/global/dwolla-logo-white-text.svg';
import ResetButton from './ResetButton';
import PreviousButton from './PreviousButton';
import NextButton from './NextButton';
import config from '../../../config';
import HelpButtonConnection from './HelpButtonConnection';
import DropUpMenuConnection from './DropUpMenuConnection';
import PrivacyButton from './PrivacyButton';

const PageNav = ({
  prevLink,
  prevOnClick,
  PrevIcon,
  nextLink,
  nextText,
  nextPending,
  nextDisabled,
  nextOnClick,
  NextIcon,
  bg,
  Logo,
  showBar,
  showReset,
  resetFunc,
}) => {
  const location = useLocation();

  return (
    <div className={styles.pageNavWrapper}>
      {showBar && <BSProgressBar className={styles.progressNav} now={100} />}

      <div
        data-testid="PageNav"
        className={classNames(styles.pageNavComponent, styles[bg])}
      >
        <div className={styles.logo}>
          <Logo />
        </div>
        <div className={styles.buttons}>
          {config.tourBubblesEnabled && <HelpButtonConnection />}
          {showReset && <ResetButton onClick={resetFunc} />}
          <PreviousButton
            prevLink={prevLink}
            prevOnClick={prevOnClick}
            PrevIcon={PrevIcon}
            variant={bg === 'white' ? 'dwolla' : 'outline-light'}
            size="sm"
          />
          <NextButton
            nextLink={nextLink}
            nextText={nextText}
            nextPending={nextPending}
            nextDisabled={nextDisabled}
            nextOnClick={nextOnClick}
            NextIcon={NextIcon}
            variant={bg === 'white' ? 'dwolla' : 'outline-light'}
            size="sm"
          />
        </div>
        {location.pathname === '/' ? (
          <PrivacyButton />
        ) : (
          <DropUpMenuConnection />
        )}
      </div>
    </div>
  );
};

PageNav.propTypes = {
  prevLink: PropTypes.string,
  prevOnClick: PropTypes.func,
  PrevIcon: PropTypes.elementType,
  nextLink: PropTypes.string,
  nextText: PropTypes.string,
  nextPending: PropTypes.bool,
  nextDisabled: PropTypes.bool,
  nextOnClick: PropTypes.func,
  NextIcon: PropTypes.elementType,
  bg: PropTypes.oneOf(['purple', 'gradient', 'white']),
  Logo: PropTypes.elementType,
  showBar: PropTypes.bool,
  showReset: PropTypes.bool,
  resetFunc: PropTypes.func,
};

PageNav.defaultProps = {
  prevLink: '',
  prevOnClick: null,
  PrevIcon: undefined,
  nextLink: '',
  nextText: 'Next',
  nextPending: false,
  nextDisabled: false,
  nextOnClick: null,
  NextIcon: undefined,
  bg: 'purple',
  Logo: WhiteTextLogo,
  showBar: true,
  showReset: false,
  resetFunc: () => {},
};

export default PageNav;
