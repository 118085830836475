import React from 'react';
import PropTypes from 'prop-types';
import styles from './StepOverview.module.scss';
import StepCard from '../../atoms/step-card/StepCard';

const StepOverview = ({ steps }) => (
  <div className={styles.steps}>
    {steps.map((step, index) => (
      <StepCard stepInfo={step} stepNum={index + 1} key={step.name} />
    ))}
  </div>
);

StepOverview.propTypes = {
  steps: PropTypes.arrayOf(
    PropTypes.shape({
      name: PropTypes.string,
      description: PropTypes.string,
    })
  ).isRequired,
};

export default StepOverview;
