import React from 'react';
import Note from './Note';

const MicroDepositNote = () => (
  <Note>
    Micro-deposits require an account and routing number.
    <br />
    Takes 1-3 business days for two small deposits to appear in the account.
  </Note>
);

export default MicroDepositNote;
