import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import PhonePage from '../../../phone-page/PhonePage';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import AttachBankAccountDashboard from '../../../../molecules/dashboard-view/AttachBankAccountDashboard';
import PageNav from '../../../../molecules/page-nav/PageNav';
import FundingSourcesScreen from '../../../phone-screens/2-attach-bank/FundingSourcesScreen';
import LoadingScreen from '../../../phone-screens/LoadingScreen';
import config from '../../../../../config';
import BankAccountHeading from '../../../../../utils/BankAccountHeading';

const FundingSourcesPage = ({
  theme,
  stepPercentage,
  loading,
  customerId,
  fundingSourceUrl,
  successNotificationText,
  fundingSources,
  submitLoadFundingSources,
  handleAddBankClicked,
  variation,
  transferLink,
  resetAttachBank,
  subStep,
}) => {
  useEffect(() => {
    submitLoadFundingSources();
  }, [submitLoadFundingSources]);

  return (
    <PhonePage
      theme={theme}
      stepPercentage={stepPercentage}
      currentStep={2}
      currentSubStep={BankAccountHeading(variation, stepPercentage)}
      responseCodeComponent={
        <CreatedResponseCode resourceUrl={fundingSourceUrl} />
      }
      dashboardComponent={
        <AttachBankAccountDashboard customerId={customerId} />
      }
      pageNavComponent={
        <PageNav
          showReset={!!subStep}
          resetFunc={resetAttachBank}
          prevLink={config.tourFlow.attachBank.back}
          nextText="Next"
          nextOnClick={handleAddBankClicked}
          nextLink={transferLink}
        />
      }
    >
      {!!loading && <LoadingScreen />}
      {!loading && (
        <FundingSourcesScreen
          theme={theme}
          successNotificationText={successNotificationText}
          fundingSources={fundingSources}
          handleAddBankClicked={handleAddBankClicked}
          transferLink={transferLink}
        />
      )}
    </PhonePage>
  );
};

FundingSourcesPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number.isRequired,
  loading: PropTypes.bool.isRequired,
  customerId: PropTypes.string.isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  successNotificationText: PropTypes.string.isRequired,
  fundingSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  submitLoadFundingSources: PropTypes.func.isRequired,
  handleAddBankClicked: PropTypes.func,
  transferLink: PropTypes.string,
  variation: PropTypes.string,
  resetAttachBank: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

FundingSourcesPage.defaultProps = {
  handleAddBankClicked: null,
  transferLink: null,
  variation: '',
};

export default FundingSourcesPage;
