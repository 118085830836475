import React from 'react';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Tooltip from 'react-bootstrap/Tooltip';
import PropTypes from 'prop-types';
import { Button } from 'react-bootstrap';
import { ReactComponent as ResetIcon } from '../../../assets/images/icons/icon-reset.svg';
import styles from './PageNav.module.scss';

const ResetButton = ({ onClick }) => {
  const renderTooltip = props => (
    /* eslint-disable react/jsx-props-no-spreading */
    <Tooltip id="button-tooltip" {...props}>
      This action will reset the data for this and all subsequent steps.
    </Tooltip>
  );
  return (
    <OverlayTrigger
      placement="top"
      delay={{ show: 250, hide: 400 }}
      overlay={renderTooltip}
    >
      <Button
        className={styles.resetButton}
        variant="outline-light"
        onClick={onClick}
      >
        <ResetIcon />
      </Button>
    </OverlayTrigger>
  );
};

ResetButton.propTypes = {
  onClick: PropTypes.func,
};

ResetButton.defaultProps = {
  onClick: () => {},
};

export default ResetButton;
