import React from 'react';
import PropTypes from 'prop-types';
import styles from './HeroTypography.module.scss';

export const Headline = ({ children, preText }) => (
  <h1 className={styles.headline}>
    {preText && (
      <>
        <span className={styles.preText}>{preText}</span>
        <br />
      </>
    )}
    {children}
  </h1>
);

Headline.propTypes = {
  children: PropTypes.node.isRequired,
  preText: PropTypes.string,
};

Headline.defaultProps = {
  preText: null,
};

export const SubHeadline = ({ children }) => (
  <h2 className={styles.subHeadline}>{children}</h2>
);

SubHeadline.propTypes = {
  children: PropTypes.node.isRequired,
};

export const Description = ({ children }) => (
  <div className={styles.description}>{children}</div>
);

Description.propTypes = {
  children: PropTypes.node.isRequired,
};
