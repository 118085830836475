import React from 'react';
import PropTypes from 'prop-types';
import styles from './VideoLoop.module.scss';

const HomeVideo = ({ src, title }) => {
  return (
    <video
      muted
      id={`HomeVideo-${title}`}
      autoPlay
      playsInline
      title={title}
      className={styles.videoLoopComponent}
      key={src}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

HomeVideo.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default HomeVideo;
