import { randomUuid } from '../../utils/id-generator';
import { chance } from '../../utils/autofill';
import { postJson, get } from '../../utils/api';

export const SUBMIT_CREATE_CUSTOMER = 'SUBMIT_CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_FAILURE = 'CREATE_CUSTOMER_FAILURE';
export const RESET_CREATE_CUSTOMER = 'RESET_CREATE_CUSTOMER';

export const SUBMIT_LOAD_FACILITATOR = 'SUBMIT_LOAD_FACILITATOR';
export const LOAD_FACILITATOR_SUCCESS = 'LOAD_FACILITATOR_SUCCESS';
export const LOAD_FACILITATOR_FAILURE = 'LOAD_FACILITATOR_FAILURE';

const initialState = {
  subStep: 0,
  loading: true,
  pending: false,
  facilitatorCustomer: {},
  createdCustomer: {},
};

const generateFakeCustomer = (firstName, lastName) => {
  const id = randomUuid();
  return {
    _links: {
      self: {
        href: `https://api-sandbox.dwolla.com/customers/${id}`,
      },
    },
    id,
    firstName,
    lastName,
  };
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case SUBMIT_CREATE_CUSTOMER:
      return {
        ...state,
        pending: true,
      };
    case CREATE_CUSTOMER_SUCCESS:
      return {
        ...state,
        subStep: 1,
        pending: false,
        createdCustomer: action.body,
      };
    case CREATE_CUSTOMER_FAILURE:
      return {
        ...state,
        subStep: 1,
        pending: false,
        createdCustomer: generateFakeCustomer(
          action.firstName,
          action.lastName
        ),
      };
    case SUBMIT_LOAD_FACILITATOR:
      return {
        ...state,
        loading: true,
      };
    case LOAD_FACILITATOR_SUCCESS:
      return {
        ...state,
        loading: false,
        facilitatorCustomer: action.body,
      };
    case LOAD_FACILITATOR_FAILURE:
      return {
        ...state,
        loading: false,
        facilitatorCustomer: generateFakeCustomer(
          chance.first(),
          chance.last()
        ),
      };
    case RESET_CREATE_CUSTOMER:
      return {
        ...state,
        subStep: 0,
        loading: true,
        pending: false,
        createdCustomer: {},
      };
    default:
      return state;
  }
}

export const submitCreateUnverifiedCustomer = ({
  firstName,
  lastName,
  email,
}) => ({
  types: [
    SUBMIT_CREATE_CUSTOMER,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_FAILURE,
  ],
  payload: {
    firstName,
    lastName,
  },
  callAPI: () =>
    postJson('/customers', {
      firstName,
      lastName,
      email,
      ipAddress: '99.99.99.99',
    }),
});

export const submitCreateVerifiedCustomer = ({
  firstName,
  lastName,
  email,
  dob,
  ssn,
  address1,
  city,
  state,
  postalCode,
}) => ({
  types: [
    SUBMIT_CREATE_CUSTOMER,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_FAILURE,
  ],
  payload: {
    firstName,
    lastName,
  },
  callAPI: () =>
    postJson('/customers', {
      type: 'personal',
      firstName,
      lastName,
      email,
      dateOfBirth: dob,
      ssn,
      address1,
      city,
      state,
      postalCode,
      ipAddress: '99.99.99.99',
    }),
});

export const submitLoadFacilitatorCustomer = () => ({
  types: [
    SUBMIT_LOAD_FACILITATOR,
    LOAD_FACILITATOR_SUCCESS,
    LOAD_FACILITATOR_FAILURE,
  ],
  callAPI: () => get('/customers/facilitator'),
});

export const resetCreateCustomer = () => ({
  type: RESET_CREATE_CUSTOMER,
});
