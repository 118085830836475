/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import AppBuilderPage from './AppBuilderPage';

const SelfServiceAppBuilderPage = passThroughProps => (
  <AppBuilderPage
    {...passThroughProps}
    PageHeading={() => (
      <Heading variant="small">Creating a Branded Experience</Heading>
    )}
    PageSubHeading={() => (
      <SubHeading variant="small">
        Choose from the pre-populated brands for the purpose of the demo. The
        next screen will be an example of what your end users will experience.
      </SubHeading>
    )}
  />
);

export default SelfServiceAppBuilderPage;
