import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import PageNav from '../../../../molecules/page-nav/PageNav';
import AttachBankAccountDashboard from '../../../../molecules/dashboard-view/AttachBankAccountDashboard';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import BankVerifiedSendToFriendScreen from '../../../phone-screens/2-attach-bank/BankVerifiedSendToFriendScreen';
import BankVerifiedPayBillScreen from '../../../phone-screens/2-attach-bank/BankVerifiedPayBillScreen';
import config from '../../../../../config';

const VerifiedBankAttachedPage = ({
  theme,
  customerId,
  fundingSourceUrl,
  inviterFirstName,
  inviterLastName,
  billAmount,
  resetAttachBank,
  subStep,
}) => {
  return (
    <PhonePage
      theme={theme}
      stepPercentage={100}
      currentStep={2}
      currentSubStep="Bank Account Attached &amp; Verified!"
      responseCodeComponent={
        <CreatedResponseCode resourceUrl={fundingSourceUrl} />
      }
      dashboardComponent={
        <AttachBankAccountDashboard customerId={customerId} />
      }
      pageNavComponent={
        <PageNav
          showReset={!!subStep}
          resetFunc={resetAttachBank}
          prevLink={config.tourFlow.attachBank.back}
          nextLink={config.tourFlow.attachBank.next}
        />
      }
    >
      {inviterFirstName ? (
        <BankVerifiedSendToFriendScreen
          theme={theme}
          nextLink={config.tourFlow.attachBank.next}
          successNotificationText="Verified bank added!"
          customerName={`${inviterFirstName} ${inviterLastName}`}
        />
      ) : (
        <BankVerifiedPayBillScreen
          theme={theme}
          nextLink={config.tourFlow.attachBank.next}
          successNotificationText="Verified bank added!"
          billAmount={billAmount}
        />
      )}
    </PhonePage>
  );
};

VerifiedBankAttachedPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  customerId: PropTypes.string.isRequired,
  fundingSourceUrl: PropTypes.string.isRequired,
  inviterFirstName: PropTypes.string,
  inviterLastName: PropTypes.string,
  billAmount: PropTypes.string,
  resetAttachBank: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

VerifiedBankAttachedPage.defaultProps = {
  inviterFirstName: '',
  inviterLastName: '',
  billAmount: '',
};

export default VerifiedBankAttachedPage;
