import { connect } from 'react-redux';
import VerifiedBankAttachedPayBillPageWithLoader from './VerifiedBankAttachedPayBillPageWithLoader';
import { generateRandomBillAmount } from '../../../../../redux/modules/move-money';
import { resetAttachBank } from '../../../../../redux/modules/attach-bank';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  billAmount: state.moveMoney.billAmount,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  generateRandomBillAmount,
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(VerifiedBankAttachedPayBillPageWithLoader);
