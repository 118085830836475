import React from 'react';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './PageNav.module.scss';

const PrivacyButton = ({ variant, onclick }) => (
  <Button className={styles.button} variant={variant} onClick={onclick}>
    Privacy Options
  </Button>
);

PrivacyButton.propTypes = {
  variant: PropTypes.string,
  onclick: PropTypes.func,
};

PrivacyButton.defaultProps = {
  variant: 'outline-light',
  onclick: e =>
    !!e && window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open'),
};

export default PrivacyButton;
