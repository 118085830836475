import React from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import PaymentTypesStack from '../../molecules/payment-types-stack/PaymentTypesStack';
import { Heading } from '../../atoms/typography/Typography';
import { paymentTypesOptions } from '../../../utils/payment-types-options';
import Hexagons from '../../molecules/hexagons/Hexagons';
import styles from './PaymentTypesPage.module.scss';
import config from '../../../config';

const PaymentTypesPage = ({
  PageHeading,
  createdCustomer,
  fundsFlow,
  fundingSources,
}) => {
  let PaymentNav = '/create-users';

  const isCustomersStepComplete = Object.keys(createdCustomer).length > 0;
  const isFundingSourcesStepComplete =
    (fundsFlow === 'meToMe' && fundingSources.length >= 3) ||
    (fundsFlow !== 'meToMe' && fundingSources.length >= 1);

  if (isCustomersStepComplete && isFundingSourcesStepComplete) {
    PaymentNav = '/move-money';
  } else if (isCustomersStepComplete) {
    PaymentNav = '/attach-bank';
  }

  return (
    <div className={styles.paymentTypesPage}>
      <Hexagons />
      <Container className="container__center-content">
        <PageHeading />
        <PaymentTypesStack paymentOptions={paymentTypesOptions} />
      </Container>

      <PageNav
        prevLink={PaymentNav}
        nextLink={config.tourFlow.paymentTypes.next}
        bg="purple"
        showBar={false}
      />
    </div>
  );
};

PaymentTypesPage.propTypes = {
  PageHeading: PropTypes.func,
  createdCustomer: PropTypes.object.isRequired,
  fundingSources: PropTypes.array.isRequired,
  fundsFlow: PropTypes.string.isRequired,
};

PaymentTypesPage.defaultProps = {
  PageHeading: () => (
    <Heading>Tailor The Experience Using Various Payment Methods.</Heading>
  ),
};

export default PaymentTypesPage;
