import { connect } from 'react-redux';
import VerifiedBankAttachedPage from './VerifiedBankAttachedPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  inviterFirstName: state.createCustomer.facilitatorCustomer.firstName,
  inviterLastName: state.createCustomer.facilitatorCustomer.lastName,
  subStep: state.attachBank.subStep,
});

export default connect(mapStateToProps)(VerifiedBankAttachedPage);
