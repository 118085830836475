/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import ChooseBankMethodScreen from '../../../phone-screens/2-attach-bank/ChooseBankMethodScreen';
import EmptyBankRequestCode from '../../../../molecules/code/EmptyBankRequestCode';
import PageNav from '../../../../molecules/page-nav/PageNav';
import config from '../../../../../config';

const ChooseBankMethodPage = ({
  theme,
  stepPercentage,
  bearerToken,
  customerUrl,
  pending,
  offlineMode,
  setVariation,
  resetAttachBank,
  subStep,
}) => {
  const handlePlaidClicked = e => {
    e.preventDefault();
    setVariation({ variation: 'plaid' });
  };

  const handleMicroDepositsClicked = e => {
    e.preventDefault();
    setVariation({ variation: 'microDeposits' });
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={stepPercentage}
      currentStep={2}
      processing={pending}
      currentSubStep="Bank Account Verification Method"
      requestCodeComponent={
        <EmptyBankRequestCode
          {...{
            customerUrl,
            bearerToken,
          }}
        />
      }
      pageNavComponent={
        <PageNav
          showReset={!!subStep}
          resetFunc={resetAttachBank}
          prevLink={config.tourFlow.attachBank.back}
          nextOnClick={handleMicroDepositsClicked}
          nextText={offlineMode ? 'Next' : 'Use Deposit Verification'}
        />
      }
    >
      <ChooseBankMethodScreen
        {...{
          theme,
          offlineMode,
          handlePlaidClicked,
          handleMicroClicked: handleMicroDepositsClicked,
        }}
      />
    </PhonePage>
  );
};

ChooseBankMethodPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  stepPercentage: PropTypes.number,
  bearerToken: PropTypes.string.isRequired,
  customerUrl: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  offlineMode: PropTypes.bool,
  setVariation: PropTypes.func.isRequired,
  resetAttachBank: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

ChooseBankMethodPage.defaultProps = {
  stepPercentage: 0,
  offlineMode: false,
};

export default ChooseBankMethodPage;
