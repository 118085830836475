/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import UnverifiedCustomerRequestCode from '../../../../molecules/code/UnverifiedCustomerRequestCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import PhonePage from '../../../phone-page/PhonePage';
import useFocusOnPageLoad from '../../../../../hooks/focus-on-page-load';
import UnverifiedCustomerScreen from '../../../phone-screens/1-create-users/UnverifiedCustomerScreen';
import { isEmail, isPopulated } from '../../../../../utils/validators';
import {
  slowFill,
  slowFillSingle,
  chance,
} from '../../../../../utils/autofill';
import PageNavWithAutoFill from '../../../../molecules/page-nav/PageNavWithAutofill';
import bubbleSteps from '../../../../../utils/flow-control/bubble-flows';
import config from '../../../../../config';

const CreateUnverifiedCustomerPage = ({
  theme,
  bearerToken,
  pending,
  subStep,
  submitCreateCustomer,
  setBubbleSteps,
  areBubblesOpen,
  closeBubbles,
  resetCreateCustomer,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [tosChecked, setTosChecked] = useState(false);

  useEffect(() => {
    setBubbleSteps(bubbleSteps.createCustomer);
  }, [setBubbleSteps]);

  const elToFocus = useRef(null);
  useFocusOnPageLoad(elToFocus);

  const isValidInput =
    !pending &&
    !areBubblesOpen &&
    isPopulated(firstName) &&
    isPopulated(lastName) &&
    isPopulated(email) &&
    isEmail(email) &&
    tosChecked;

  const handleSubmitClicked = e => {
    e.preventDefault();
    submitCreateCustomer({ firstName, lastName, email });
  };

  const handleAutoFill = () => {
    closeBubbles();

    setFirstName('');
    setLastName('');
    setEmail('');
    setTosChecked(false);

    slowFill(chance.first(), setFirstName)
      .then(() => slowFill(chance.last(), setLastName))
      .then(() => slowFill(chance.comEmail(), setEmail))
      .then(() => slowFillSingle(true, setTosChecked));
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={50}
      currentStep={1}
      processing={pending}
      currentSubStep="Create an End User"
      requestCodeComponent={
        <UnverifiedCustomerRequestCode
          firstName={firstName}
          lastName={lastName}
          email={email}
          bearerToken={bearerToken}
        />
      }
      pageNavComponent={
        <PageNavWithAutoFill
          showReset={!!subStep}
          resetFunc={resetCreateCustomer}
          {...{
            prevLink: config.tourFlow.createUsers.back,
            isValidInput,
            pending,
            handleAutoFill,
            handleSubmitClicked,
          }}
        />
      }
    >
      <UnverifiedCustomerScreen
        {...{
          theme,
          isValidInput,
          pending,
          elToFocus,
          firstName,
          setFirstName,
          lastName,
          setLastName,
          email,
          setEmail,
          tosChecked,
          setTosChecked,
          handleSubmitClicked,
        }}
      />
    </PhonePage>
  );
};

CreateUnverifiedCustomerPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  bearerToken: PropTypes.string.isRequired,
  pending: PropTypes.bool.isRequired,
  subStep: PropTypes.number.isRequired,
  submitCreateCustomer: PropTypes.func.isRequired,
  setBubbleSteps: PropTypes.func.isRequired,
  areBubblesOpen: PropTypes.bool.isRequired,
  closeBubbles: PropTypes.func.isRequired,
  resetCreateCustomer: PropTypes.func.isRequired,
};

export default CreateUnverifiedCustomerPage;
