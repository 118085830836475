import { connect } from 'react-redux';
import PaymentTypesPage from './PaymentTypesPage';

const mapStateToProps = state => ({
  createdCustomer: state.createCustomer.createdCustomer,
  fundingSources: state.fundingSources.fundingSources,
  fundsFlow: state.general.fundsFlow,
});

export default connect(mapStateToProps)(PaymentTypesPage);
