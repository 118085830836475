import React from 'react';
import PropTypes from 'prop-types';
import PaymentTypesCard from '../../atoms/payment-types-card/PaymentTypesCard';
import styles from './PaymentTypesStack.module.scss';

const PaymentTypesStack = ({ paymentOptions }) => {
  const items = [];

  Object.keys(paymentOptions).forEach((key, index) => {
    items.push(
      <div key={[index, 'PaymentCard']}>
        <PaymentTypesCard
          label={paymentOptions[key].label}
          transferTime={paymentOptions[key].transferTime}
          headline={paymentOptions[key].headline}
          description={paymentOptions[key].description}
          logo={paymentOptions[key].logo}
        />
      </div>
    );
  });

  return <div className={styles.paymentStacks}>{items}</div>;
};

PaymentTypesStack.propTypes = {
  paymentOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
};

export default PaymentTypesStack;
