import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './Typography.module.scss';
import { themeOptions } from '../../../utils/propTypeHelper';
import { isAppContainedInIFrame } from '../../../utils/iframe';

export const Heading = ({ children, preText, variant, theme, center }) => (
  <h1
    className={classNames(styles[variant], {
      [styles[theme]]: variant === 'theme',
      [styles.center]: center,
      [styles.containedInIFrame]: isAppContainedInIFrame(),
    })}
  >
    {preText && (
      <>
        <span className={styles.preText}>{preText}</span>
        <br />
      </>
    )}
    {children}
  </h1>
);

Heading.propTypes = {
  children: PropTypes.node.isRequired,
  preText: PropTypes.string,
  variant: PropTypes.oneOf(['heading', 'small', 'dark', 'theme']),
  theme: PropTypes.oneOf(themeOptions),
  center: PropTypes.bool,
};

Heading.defaultProps = {
  preText: null,
  variant: 'heading',
  theme: 'theme1',
  center: false,
};

export const SubHeading = ({ children, variant, center }) => (
  <h2
    className={classNames(styles[variant], {
      [styles.subHeading]: variant !== 'phone',
      [styles.center]: center,
    })}
  >
    {children}
  </h2>
);

SubHeading.propTypes = {
  children: PropTypes.node.isRequired,
  variant: PropTypes.oneOf([
    '',
    'large',
    'small',
    'extraMargin',
    'phone',
    'dark',
  ]),
  center: PropTypes.bool,
};

SubHeading.defaultProps = {
  variant: '',
  center: false,
};
