import { connect } from 'react-redux';
import {
  submitAttachBank,
  decrementSubStep,
  resetAttachBank,
} from '../../../../../redux/modules/attach-bank';
import CreateUnverifiedBankPage from './CreateUnverifiedBankPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  customerUrl: state.createCustomer.createdCustomer._links.self.href,
  pending: state.attachBank.pending,
  variation: state.attachBank.variation,
  fundsFlow: state.general.fundsFlow,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  submitAttachBank,
  handleBackClicked: decrementSubStep,
  resetAttachBank,
};

const mergeProps = (stateProps, dispatchProps, ownProps) => {
  const { variation, ...remainingStateProps } = stateProps;
  const { handleBackClicked, ...remainingDispatchProps } = dispatchProps;

  return {
    ...remainingStateProps,
    handleBackClicked: variation !== 'default' ? handleBackClicked : null,
    ...remainingDispatchProps,
    ...ownProps,
  };
};

export default connect(
  mapStateToProps,
  mapDispatchToProps,
  mergeProps
)(CreateUnverifiedBankPage);
