import { connect } from 'react-redux';
// eslint-disable-next-line import/no-cycle
import { submitLoadCustomerFundingSources } from '../../../../../redux/modules/funding-sources';
import {
  submitLoadAccount,
  submitMoveMoney,
  resetMoveMoney,
} from '../../../../../redux/modules/move-money';
import PayBillPageWithLoader from './PayBillPageWithLoader';
import { verifiedFundingSourceMapper } from '../../../../../utils/funding-source-mapper';
import formatCurrency from '../../../../../utils/currency';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  loading: state.fundingSources.loading || state.moveMoney.loadingAcct,
  pending: state.moveMoney.pending,
  headingPreText: 'Pay Your Bill',
  headingText: formatCurrency(state.moveMoney.billAmount),
  billAmount: state.moveMoney.billAmount,
  sendButtonText: 'Pay Bill',
  fundingSources: verifiedFundingSourceMapper(
    state.fundingSources.fundingSources
  ),
  defaultDestinationUrl: state.moveMoney.account.selfLink,
  subStep: state.moveMoney.subStep,
});

const mapDispatchToProps = {
  submitLoadFundingSources: submitLoadCustomerFundingSources,
  submitLoadAccount,
  submitMoveMoney,
  resetMoveMoney,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(PayBillPageWithLoader);
