import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import Hero from '../../molecules/hero/Hero';
import { Headline, SubHeadline } from '../../atoms/typography/HeroTypography';
import PageNav from '../../molecules/page-nav/PageNav';
import StepOverview from '../../molecules/step-overview/StepOverview';
import config from '../../../config';
import styles from './StepOverviewPage.module.scss';

const steps = [
  {
    name: 'Create an End User in the API',
    description:
      'This is a Dwolla customer type that represents the user sending funds.',
    link: 'https://developers.dwolla.com/guides/send-money/create-a-customer ',
  },
  {
    name: 'Attach a Payment Account',
    description:
      'A funding source needs to be attached to our created customer. We’ll use sample bank information in this demo.',
    link: 'https://developers.dwolla.com/guides/send-money/add-funding-source ',
  },
  {
    name: 'Initiate a Transfer',
    description:
      'Using your newly created funding source, initiate a transfer.',
    link: 'https://developers.dwolla.com/guides/send-money/create-transfer',
  },
];

const StepOverviewPage = ({
  HeroHeadline,
  HeroSubHeadline,
  HeroDescription,
}) => (
  <div className="step-overview">
    <Hero
      HeroHeadline={HeroHeadline}
      HeroSubHeadline={HeroSubHeadline}
      HeroDescription={HeroDescription}
    />
    <Container className={classNames(styles.container)}>
      <StepOverview steps={steps} />
    </Container>
    <PageNav
      prevLink={config.tourFlow.stepOverview.back}
      nextLink={config.tourFlow.stepOverview.next}
      bg="purple"
    />
  </div>
);

StepOverviewPage.propTypes = {
  HeroHeadline: PropTypes.func,
  HeroSubHeadline: PropTypes.func,
  HeroDescription: PropTypes.func,
};

StepOverviewPage.defaultProps = {
  HeroHeadline: () => (
    <Headline>
      Seamlessly Automate Payments Between Bank Accounts In 3 Easy Steps
    </Headline>
  ),
  HeroSubHeadline: () => (
    <>
      <SubHeadline>
        The Dwolla API requires an end user (customer) be created, a funding
        source be <br /> attached and a trigger to initiate a transfer. Before
        initiating a payment, this demo will walk you through how to:
      </SubHeadline>
    </>
  ),
  HeroDescription: () => null,
};

export default StepOverviewPage;
