/* eslint-disable react/jsx-props-no-spreading */

import React from 'react';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import ConclusionPage from './ConclusionPage';
import HubSpotForm from '../../atoms/hubspot-form/HubSpotForm';

const SelfServiceConclusionPage = passThroughProps => (
  <ConclusionPage
    {...passThroughProps}
    PageHeading={() => (
      <Heading variant="small" center>
        Give Your Business the Flexibility To Innovate
      </Heading>
    )}
    PageSubHeading={() => (
      <SubHeading variant="small" center>
        Let us help you build a solution that supports your needs for scale.
      </SubHeading>
    )}
    PageIFrame={() => (
      <HubSpotForm hubSpotFormId="ffe1d6db-31f1-4b33-b118-4c83ccfb10a8" />
    )}
    PagePostIFrameContent={() => (
      <div>
        <a href="https://www.dwolla.com">Visit Dwolla&apos;s Website</a>
        &nbsp; &#124; &nbsp;
        <a href="https://accounts-sandbox.dwolla.com/sign-up">
          Start Building in the Sandbox
        </a>
      </div>
    )}
  />
);

export default SelfServiceConclusionPage;
