export default function BankAccountHeading(variation, stepPercentage) {
  if (variation === 'plaid') {
    return `Bank Account Attached & Verified!`;
  }
  switch (stepPercentage) {
    case 66.7:
      return 'Bank Account Verified';

    default:
      return 'Bank Account Attached!';
  }
}
