import React from 'react';
import PropTypes from 'prop-types';
import StepIcon from '../step-icon/StepIcon';
import styles from './StepCard.module.scss';

const StepCard = ({ stepInfo, stepNum }) => {
  return (
    <div className={styles.StepCard}>
      <StepIcon className={styles.icon} stepNum={stepNum} />
      <h2 className={styles.cardTitle}>{stepInfo.name}</h2>
      <p className={styles.cardContent}>{stepInfo.description}</p>
    </div>
  );
};

StepCard.propTypes = {
  stepInfo: PropTypes.object.isRequired,
  stepNum: PropTypes.number.isRequired,
};

export default StepCard;
