import React from 'react';
import PropTypes from 'prop-types';
import styles from './PaymentTypesCard.module.scss';
import { paymentOptions } from '../../../utils/propTypeHelper';

const PaymentTypesCard = ({
  label,
  transferTime,
  headline,
  description,
  logo,
}) => {
  return (
    <div className={styles.cardWrapper}>
      <div className={styles.circle}>
        <img src={logo} alt={`${logo}`} />
      </div>

      <div className={styles.leftContent}>
        <div className={styles.title}>{label}</div>
        <div className={styles.transferLabel}>TRANSFER TIME</div>
        <div className={styles.transferTime}>{transferTime}</div>
      </div>

      <div className={styles.rightContent}>
        <div className={styles.headline}>{headline}</div>
        <div className={styles.description}>{description}</div>
      </div>
    </div>
  );
};

PaymentTypesCard.propTypes = {
  label: PropTypes.string.isRequired,
  transferTime: PropTypes.string.isRequired,
  headline: PropTypes.string.isRequired,
  description: PropTypes.string.isRequired,
  logo: PropTypes.oneOf(paymentOptions).isRequired,
};

export default PaymentTypesCard;
