import { connect } from 'react-redux';
import BankVerifiedPage from './BankVerifiedPage';
import { resetAttachBank } from '../../../../../redux/modules/attach-bank';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  inviterFirstName: state.createCustomer.facilitatorCustomer.firstName,
  inviterLastName: state.createCustomer.facilitatorCustomer.lastName,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankVerifiedPage);
