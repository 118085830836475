import React from 'react';
import PropTypes from 'prop-types';
import PhonePage from '../../../phone-page/PhonePage';
import CreatedResponseCode from '../../../../molecules/code/CreatedResponseCode';
import MoveMoneyDashboard from '../../../../molecules/dashboard-view/MoveMoneyDashboard';
import PageNav from '../../../../molecules/page-nav/PageNav';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import TransferCompleteScreen from '../../../phone-screens/3-move-money/TransferCompleteScreen';
import config from '../../../../../config';

const TransferCompletePage = ({
  theme,
  text,
  amount,
  transferUrl,
  customerId,
  transferId,
  resetMoveMoney,
  subStep,
}) => (
  <PhonePage
    theme={theme}
    stepPercentage={100}
    currentStep={3}
    currentSubStep="Transaction Complete!"
    responseCodeComponent={<CreatedResponseCode resourceUrl={transferUrl} />}
    dashboardComponent={
      <MoveMoneyDashboard customerId={customerId} transferId={transferId} />
    }
    pageNavComponent={
      <PageNav
        showReset={!!subStep}
        resetFunc={resetMoveMoney}
        prevLink={config.tourFlow.moveMoney.back}
        nextLink={config.tourFlow.moveMoney.next}
      />
    }
  >
    <TransferCompleteScreen theme={theme} text={text} amount={amount} />
  </PhonePage>
);

TransferCompletePage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  text: PropTypes.string.isRequired,
  amount: PropTypes.string.isRequired,
  transferUrl: PropTypes.string.isRequired,
  customerId: PropTypes.string.isRequired,
  transferId: PropTypes.string.isRequired,
  resetMoveMoney: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

export default TransferCompletePage;
