import FundsFlowsPage from '../components/organisms/funds-flows-page/FundsFlowsPage';
import SelfServiceFundsFlowsPage from '../components/organisms/funds-flows-page/SelfServiceFundsFlowsPage';
import AppBuilderPage from '../components/organisms/app-builder-page/AppBuilderPage';
import SelfServiceAppBuilderPage from '../components/organisms/app-builder-page/SelfServiceAppBuilderPage';
import ConclusionPage from '../components/organisms/conclusion-page/ConclusionPage';
import SelfServiceConclusionPage from '../components/organisms/conclusion-page/SelfServiceConclusionPage';

const pageVariations = {
  fundsFlows: {
    default: FundsFlowsPage,
    selfService: SelfServiceFundsFlowsPage,
  },
  appBuilder: {
    default: AppBuilderPage,
    selfService: SelfServiceAppBuilderPage,
  },
  conclusion: {
    default: ConclusionPage,
    selfService: SelfServiceConclusionPage,
  },
};

const getPageVariation = (page, variation) =>
  pageVariations[page][variation]
    ? pageVariations[page][variation]
    : pageVariations[page].default;

export default getPageVariation;
