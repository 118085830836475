import { connect } from 'react-redux';
import {
  submitCreateVerifiedCustomer,
  resetCreateCustomer,
} from '../../../../../redux/modules/create-customer';
import {
  setBubbleSteps,
  closeBubbles,
} from '../../../../../redux/modules/tour-bubbles';
import CreateVerifiedCustomerPage from './CreateVerifiedCustomerPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  pending: state.createCustomer.pending,
  subStep: state.createCustomer.subStep,
  areBubblesOpen: state.tourBubbles.areBubblesOpen,
});

const mapDispatchToProps = {
  submitCreateCustomer: submitCreateVerifiedCustomer,
  setBubbleSteps,
  closeBubbles,
  resetCreateCustomer,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateVerifiedCustomerPage);
