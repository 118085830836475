import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './PageNav.module.scss';
import ConditionalWrapper from '../conditional-wrapper/ConditionalWrapper';
import { ReactComponent as LeftIcon } from '../../../assets/images/icons/icon-arrow-left.svg';

const PreviousButton = ({ prevLink, prevOnClick, PrevIcon, variant, size }) =>
  (!!prevLink || !!prevOnClick) && (
    <ConditionalWrapper
      condition={!!prevLink && !prevOnClick}
      wrapper={children => <Link to={prevLink}>{children}</Link>}
    >
      <Button
        data-testid="prevButton"
        variant={variant}
        className={styles.button}
        onClick={prevOnClick}
        size={size}
      >
        <span className={styles.buttonText}>Back</span>
        <span className={styles.buttonIcon} aria-label="Back">
          <PrevIcon />
        </span>
      </Button>
    </ConditionalWrapper>
  );

PreviousButton.propTypes = {
  prevLink: PropTypes.string,
  prevOnClick: PropTypes.func,
  PrevIcon: PropTypes.elementType,
  variant: PropTypes.oneOf(['dwolla', 'outline-light']),
  size: PropTypes.oneOf(['lg', 'sm']),
};

PreviousButton.defaultProps = {
  prevLink: null,
  prevOnClick: null,
  PrevIcon: LeftIcon,
  variant: 'outline-light',
  size: 'sm',
};

export default PreviousButton;
