import React from 'react';
import PropTypes from 'prop-types';
import {
  ButtonToolbar,
  ToggleButtonGroup,
  ToggleButton,
} from 'react-bootstrap';

import styles from './ThemePicker.module.scss';
import { themeOptionsOptional } from '../../../utils/propTypeHelper';
import ThemePickerButton from './ThemePickerButton';

const ThemePicker = ({ initialValue, changeHandler }) => {
  return (
    <ButtonToolbar className={styles.buttonToolbar}>
      <ToggleButtonGroup
        type="radio"
        className={styles.buttons}
        name="themePicker"
        defaultValue={initialValue}
        onChange={changeHandler}
      >
        <ToggleButton value="theme1" variant="card">
          <ThemePickerButton theme="theme1" label="Theme 1" />
        </ToggleButton>
        <ToggleButton value="theme2" variant="card">
          <ThemePickerButton theme="theme2" label="Theme 2" />
        </ToggleButton>
        <ToggleButton value="theme3" variant="card">
          <ThemePickerButton theme="theme3" label="Theme 3" />
        </ToggleButton>
        <ToggleButton value="theme4" variant="card">
          <ThemePickerButton theme="theme4" label="Theme 4" />
        </ToggleButton>
      </ToggleButtonGroup>
    </ButtonToolbar>
  );
};

ThemePicker.propTypes = {
  initialValue: PropTypes.oneOf(themeOptionsOptional),
  changeHandler: PropTypes.func.isRequired,
};

ThemePicker.defaultProps = {
  initialValue: '',
};

export default ThemePicker;
