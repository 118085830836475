import send from '../assets/images/funds-flows/Send.mp4';
import receive from '../assets/images/funds-flows/Receive.mp4';
import facilitate from '../assets/images/funds-flows/Facilitate.mp4';
import meToMe from '../assets/images/funds-flows/Me-to-Me.mp4';
import home from '../assets/images/home/Home-Video.mp4';

export const flowOptions = {
  send: {
    label: 'Send',
    video: send,
    headline: 'Automate disbursements to personal or business accounts. ',
    description:
      'A disbursements funds flow could be payouts to merchants for good & services',
    key: 0,
  },
  receive: {
    label: 'Receive',
    video: receive,
    headline: 'Automate collecting payments from businesses or consumers. ',
    description:
      'A receive funds flow could be collecting monthly dues for an insurance premium.',
    key: 1,
  },
  facilitate: {
    label: 'Facilitate',
    video: facilitate,
    headline:
      'Automate the transfer of money between multiple users’ accounts. ',
    description: `Facilitate funds flow enables transactions between users on your platform. For example, sending funds between individuals,
    like a tenant making rental payments to a landlord within a proptech application or business making a payment to a vendor.`,
    key: 2,
  },
  meToMe: {
    label: 'Me-to-Me',
    video: meToMe,
    headline:
      'Automate the transfer of money between multiple accounts owned by a single user. ',
    description:
      'Me to me funds flows involve an end user sending funds to another account, owned by that same end user. ',
    key: 3,
  },
};

export const homeOptions = {
  home: {
    label: 'Home',
    video: home,
  },
};

export default flowOptions;
