import React from 'react';
import PropTypes from 'prop-types';
import { ReactComponent as step0 } from '../../../assets/images/step-overview/3step-page-hexagon_blank.svg';
import { ReactComponent as step1 } from '../../../assets/images/step-overview/3step-page-hexagon_1.svg';
import { ReactComponent as step2 } from '../../../assets/images/step-overview/3step-page-hexagon_2.svg';
import { ReactComponent as step3 } from '../../../assets/images/step-overview/3step-page-hexagon_3.svg';
import styles from './StepIcon.module.scss';

const StepIcon = ({ stepNum }) => {
  const iconPool = {
    1: step1,
    2: step2,
    3: step3,
  };

  const SelectedIcon = iconPool[stepNum] || step0;

  return <SelectedIcon className={styles.icon} />;
};

StepIcon.propTypes = {
  stepNum: PropTypes.number,
};

StepIcon.defaultProps = {
  stepNum: 0,
};
export default StepIcon;
