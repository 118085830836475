import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './PhoneHeader.module.scss';

import { themeOptions } from '../../../utils/propTypeHelper';

import theme1 from '../../../assets/images/phone-interface/icon-white-personal-finance-app.svg';
import theme2 from '../../../assets/images/phone-interface/icon-white-insurance-app.svg';
import theme3 from '../../../assets/images/phone-interface/icon-white-marketplace-app.svg';
import theme4 from '../../../assets/images/phone-interface/icon-white-rental-app.svg';

const logos = {
  theme1,
  theme2,
  theme3,
  theme4,
};

const PhoneHeader = ({ theme }) => {
  return (
    <div className={classNames(styles.phoneHeaderComponent, styles[theme])}>
      <div className={styles.logo}>
        <img src={logos[theme]} alt={`${theme} Company`} />
      </div>
      <div className={styles.dummyText} />
    </div>
  );
};

PhoneHeader.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
};

export default PhoneHeader;
