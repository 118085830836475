import { connect } from 'react-redux';
import TransferCompletePage from './TransferCompletePage';
import { resetMoveMoney } from '../../../../../redux/modules/move-money';

const mapStateToProps = state => ({
  theme: state.general.theme,
  amount: state.moveMoney.createdTransfer.amount.value,
  transferUrl: state.moveMoney.createdTransfer._links.self.href,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  transferId: state.general.offlineMode
    ? null
    : state.moveMoney.createdTransfer.id,
  subStep: state.moveMoney.subStep,
});

const mapDispatchToProps = {
  resetMoveMoney,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(TransferCompletePage);
