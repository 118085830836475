/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import VerifiedCustomerRequestCode from '../../../../molecules/code/VerifiedCustomerRequestCode';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import PhonePage from '../../../phone-page/PhonePage';
import { useFocusOnPageLoadWithLoadingAnimation } from '../../../../../hooks/focus-on-page-load';
import VerifiedCustomerScreen from '../../../phone-screens/1-create-users/VerifiedCustomerScreen';
import { isEmail, isPopulated } from '../../../../../utils/validators';
import {
  slowFill,
  slowFillSingle,
  chance,
} from '../../../../../utils/autofill';
import PageNavWithAutoFill from '../../../../molecules/page-nav/PageNavWithAutofill';
import LoadingScreen from '../../../phone-screens/LoadingScreen';
import bubbleSteps from '../../../../../utils/flow-control/bubble-flows';
import config from '../../../../../config';

const CreateVerifiedCustomerPage = ({
  theme,
  bearerToken,
  loading,
  pending,
  heading,
  subStep,
  submitCreateCustomer,
  setBubbleSteps,
  areBubblesOpen,
  closeBubbles,
  resetCreateCustomer,
}) => {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [dob, setDob] = useState('');
  const [ssnLastFour, setSsnLastFour] = useState('');
  const [address1, setAddress1] = useState('');
  const [city, setCity] = useState('');
  const [state, setState] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [tosChecked, setTosChecked] = useState(false);

  useEffect(() => {
    setBubbleSteps(bubbleSteps.createCustomer);
  }, [setBubbleSteps]);

  const elToFocus = useRef(null);
  useFocusOnPageLoadWithLoadingAnimation(elToFocus, loading);

  const isValidInput =
    !pending &&
    !areBubblesOpen &&
    isPopulated(firstName) &&
    isPopulated(lastName) &&
    isPopulated(email) &&
    isEmail(email) &&
    isPopulated(dob) &&
    isPopulated(ssnLastFour) &&
    isPopulated(address1) &&
    isPopulated(city) &&
    isPopulated(state) &&
    isPopulated(postalCode) &&
    tosChecked;

  const handleSubmitClicked = e => {
    e.preventDefault();
    submitCreateCustomer({
      firstName,
      lastName,
      email,
      dob,
      ssn: ssnLastFour,
      address1,
      city,
      state,
      postalCode,
    });
  };

  const handleAutoFill = () => {
    closeBubbles();

    setFirstName('');
    setLastName('');
    setEmail('');
    setDob('');
    setSsnLastFour('');
    setAddress1('');
    setCity('');
    setState('');
    setPostalCode('');
    setTosChecked(false);

    const fillDob = moment(chance.birthday({ type: 'adult' })).format(
      'YYYY-MM-DD'
    );

    slowFill(chance.first(), setFirstName)
      .then(() => slowFill(chance.last(), setLastName))
      .then(() => slowFill(chance.comEmail(), setEmail))
      .then(() => slowFill(fillDob, setDob))
      .then(() => slowFill(chance.ssn({ ssnFour: true }), setSsnLastFour))
      .then(() => slowFill(chance.address(), setAddress1))
      .then(() => slowFill(chance.city(), setCity))
      .then(() => slowFill(chance.state(), setState))
      .then(() => slowFill(chance.zip(), setPostalCode))
      .then(() => slowFillSingle(true, setTosChecked));
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={50}
      currentStep={1}
      processing={pending}
      currentSubStep="Create an End User"
      requestCodeComponent={
        <VerifiedCustomerRequestCode
          {...{
            firstName,
            lastName,
            email,
            dob,
            ssnLastFour,
            address1,
            city,
            state,
            postalCode,
            bearerToken,
          }}
        />
      }
      pageNavComponent={
        <PageNavWithAutoFill
          showReset={!!subStep}
          resetFunc={resetCreateCustomer}
          {...{
            prevLink: config.tourFlow.createUsers.back,
            isValidInput,
            loading,
            pending,
            handleAutoFill,
            handleSubmitClicked,
          }}
        />
      }
    >
      {!!loading && <LoadingScreen />}
      {!loading && (
        <VerifiedCustomerScreen
          {...{
            theme,
            isValidInput,
            pending,
            elToFocus,
            heading,
            firstName,
            setFirstName,
            lastName,
            setLastName,
            email,
            setEmail,
            dob,
            setDob,
            ssnLastFour,
            setSsnLastFour,
            address1,
            setAddress1,
            city,
            setCity,
            state,
            setState,
            postalCode,
            setPostalCode,
            tosChecked,
            setTosChecked,
            handleSubmitClicked,
          }}
        />
      )}
    </PhonePage>
  );
};

CreateVerifiedCustomerPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  bearerToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  subStep: PropTypes.number.isRequired,
  heading: PropTypes.string,
  submitCreateCustomer: PropTypes.func.isRequired,
  setBubbleSteps: PropTypes.func.isRequired,
  areBubblesOpen: PropTypes.bool.isRequired,
  closeBubbles: PropTypes.func.isRequired,
  resetCreateCustomer: PropTypes.func.isRequired,
};

CreateVerifiedCustomerPage.defaultProps = {
  heading: '',
};

export default CreateVerifiedCustomerPage;
