import React, { useEffect } from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import PageNav from '../../molecules/page-nav/PageNav';
import VideoLoopSelector from '../../molecules/video-loop-selector/VideoLoopSelector';
import fundsFlows from '../../../utils/flow-control/funds-flows';
import { flowOptions } from '../../../utils/video-options';
import config from '../../../config';
import Hero from '../../molecules/hero/Hero';
import styles from './FundsFlowsPage.module.scss';

const FundsFlowsPage = ({ fundsFlow, setFundsFlow, fundsFlowSetByUser }) => {
  useEffect(() => {
    fundsFlowSetByUser();
  }, [fundsFlowSetByUser]);

  return (
    <div className="text-center">
      <Hero />
      <Container className="container__center-content_funds">
        <div className={styles.content_box}>
          <VideoLoopSelector
            videoOptions={flowOptions}
            selectedVideo={fundsFlow}
            setSelectedVideo={setFundsFlow}
          />
        </div>
      </Container>

      <PageNav
        prevLink={config.tourFlow.fundsFlows.back}
        nextLink={config.tourFlow.fundsFlows.next}
        bg="purple"
        nextDisabled={!fundsFlows[fundsFlow].enabled}
      />
    </div>
  );
};

FundsFlowsPage.propTypes = {
  fundsFlow: PropTypes.string.isRequired,
  setFundsFlow: PropTypes.func.isRequired,
  fundsFlowSetByUser: PropTypes.func.isRequired,
};

export default FundsFlowsPage;
