import React from 'react';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PhonePage.module.scss';
import ProgressBar from '../../molecules/progress-bar/ProgressBar';
import PhoneInterface from '../../molecules/phone-interface/PhoneInterface';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import { themeOptions } from '../../../utils/propTypeHelper';
import LoadingImage from '../../../assets/images/global/dwolla-loading.gif';
import TourBubblesConnection from '../../molecules/tour-bubbles/TourBubblesConnection';
import config from '../../../config';

const PhonePage = ({
  children,
  theme,
  currentStep,
  stepPercentage,
  processing,
  disablePhoneScrolling,
  requestCodeComponent,
  responseCodeComponent,
  dashboardComponent,
  noteComponent,
  pageNavComponent,
  currentSubStep,
}) => (
  <div className={styles.bg}>
    <ProgressBar currentStep={currentStep} stepPercentage={stepPercentage} />

    <Container className="container__center-content">
      <div className={styles.content}>
        <div className={styles.phoneWrap}>
          <PhoneInterface
            position="left"
            theme={theme}
            disablePhoneScrolling={disablePhoneScrolling}
          >
            <div className={styles.phoneContents}>{children}</div>
          </PhoneInterface>
        </div>

        <div className={styles.codeWrap}>
          {requestCodeComponent && !processing && (
            <div className="bubble-code-section">
              <Heading variant="dark">{currentSubStep}</Heading>
              <SubHeading variant="dark">API Request</SubHeading>
              {requestCodeComponent}
            </div>
          )}
          {!!processing && (
            <div className={styles.processing}>
              <Heading variant="dark">{currentSubStep}</Heading>
              <SubHeading variant="dark">
                Dwolla Processing Request...
              </SubHeading>
              <div className={styles.processingImg}>
                <img src={LoadingImage} alt="Loading, please wait..." />
              </div>
            </div>
          )}
          {responseCodeComponent && (
            <div className={classNames('bubble-code-section', styles.noShrink)}>
              <Heading variant="dark">{currentSubStep}</Heading>
              <SubHeading variant="dark">API Response</SubHeading>
              {responseCodeComponent}
            </div>
          )}
          {dashboardComponent && (
            <div
              className={classNames(
                'bubble-dashboard-section',
                styles.dashboard
              )}
            >
              <Heading variant="dark">Dwolla Dashboard</Heading>
              {dashboardComponent}
            </div>
          )}
          {noteComponent && !processing && (
            <div className={styles.note}>{noteComponent}</div>
          )}
        </div>
      </div>
    </Container>

    {pageNavComponent}

    {config.tourBubblesEnabled && <TourBubblesConnection />}
  </div>
);

PhonePage.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(themeOptions).isRequired,
  currentStep: PropTypes.number.isRequired,
  stepPercentage: PropTypes.number.isRequired,
  processing: PropTypes.bool,
  disablePhoneScrolling: PropTypes.bool,
  requestCodeComponent: PropTypes.node,
  responseCodeComponent: PropTypes.node,
  dashboardComponent: PropTypes.node,
  noteComponent: PropTypes.node,
  pageNavComponent: PropTypes.node.isRequired,
  currentSubStep: PropTypes.string.isRequired,
};

PhonePage.defaultProps = {
  processing: false,
  disablePhoneScrolling: false,
  requestCodeComponent: null,
  responseCodeComponent: null,
  dashboardComponent: null,
  noteComponent: null,
};

export default PhonePage;
