import PropTypes from 'prop-types';
import PageMapper from './PageMapper';
import fundsFlows from './flow-control/funds-flows';

const componentMap = {
  send: fundsFlows.send.createSteps,
  receive: fundsFlows.receive.createSteps,
  facilitate: fundsFlows.facilitate.createSteps,
  meToMe: fundsFlows.meToMe.createSteps,
};

const CreatePageMapper = ({ fundsFlow, subStep }) => {
  return PageMapper({ componentMap, fundsFlow, subStep });
};

CreatePageMapper.propTypes = {
  fundsFlow: PropTypes.string.isRequired,
  subStep: PropTypes.number.isRequired,
};

export default CreatePageMapper;
