import { connect } from 'react-redux';
import MicroDepositsPage from './MicroDepositsPage';
import {
  submitVerifyBank,
  decrementSubStep,
  resetAttachBank,
} from '../../../../../redux/modules/attach-bank';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  pending: state.attachBank.pending,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  submitVerifyBank,
  handleBackClicked: decrementSubStep,
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(MicroDepositsPage);
