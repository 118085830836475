import React from 'react';
import classNames from 'classnames';
import { Container } from 'react-bootstrap';
import PageNav from '../../molecules/page-nav/PageNav';
import styles from './HomePage.module.scss';
import config from '../../../config';
import { Heading } from '../../atoms/typography/Typography';
import { homeOptions } from '../../../utils/video-options';
import HomeVideo from '../../atoms/video-loop/HomeVideo';

const HomePage = () => {
  return (
    <div className={styles.homePage}>
      <Heading className="heading">
        Demo: &nbsp; <strong>Build The Experience Your End Users Expect</strong>
      </Heading>
      <Container
        className={classNames('container__center-content', styles.container)}
      >
        <HomeVideo
          title={homeOptions.home.label}
          src={homeOptions.home.video}
        />
      </Container>
      <PageNav
        nextLink={config.tourFlow.home.next}
        nextText="Start the Tour"
        bg="purple"
        showBar={false}
      />
    </div>
  );
};

export default HomePage;
