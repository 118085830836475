import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import styles from './ThemePickerButton.module.scss';
import { themeOptions } from '../../../utils/propTypeHelper';

import theme1 from '../../../assets/images/phone-interface/icon-personal-finance-app.svg';
import theme2 from '../../../assets/images/phone-interface/icon-insurance-app.svg';
import theme3 from '../../../assets/images/phone-interface/icon-marketplace-app.svg';
import theme4 from '../../../assets/images/phone-interface/icon-rental-app.svg';

const logos = {
  theme1,
  theme2,
  theme3,
  theme4,
};

const ThemePickerButton = ({ theme, label }) => {
  return (
    <div className={styles.themePickerButtonComponent}>
      <img src={logos[theme]} alt={`${label} Logo`} className={styles.logo} />

      <div className={styles.label}>{label}</div>

      <div className={classNames(styles.colors)}>
        <div className={styles.primary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.secondary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.tertiary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>

        <div className={styles.quaternary}>
          <div className={classNames(styles.color, styles[theme])} />
        </div>
      </div>
    </div>
  );
};

ThemePickerButton.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  label: PropTypes.string.isRequired,
};

export default ThemePickerButton;
