import React, { useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Container } from 'react-bootstrap';
import PropTypes from 'prop-types';
import styles from './AppBuilderPage.module.scss';
import PageNav from '../../molecules/page-nav/PageNav';
import BackgroundImage from '../../atoms/background-image/BackgroundImage';
import ThemePicker from '../../molecules/theme-picker/ThemePicker';
import PhoneInterface from '../../molecules/phone-interface/PhoneInterface';
import Hero from '../../molecules/hero/Hero';
import { Headline, SubHeadline } from '../../atoms/typography/HeroTypography';
import { SubHeading } from '../../atoms/typography/Typography';
import startTheme1 from '../../../assets/images/phone-interface/start-screen-personal-finance.png';
import startTheme2 from '../../../assets/images/phone-interface/start-screen-insurance.png';
import startTheme3 from '../../../assets/images/phone-interface/start-screen-marketplace.png';
import startTheme4 from '../../../assets/images/phone-interface/start-screen-rental.png';
import startUnbranded from '../../../assets/images/phone-interface/start-screen-unbranded.png';
import config from '../../../config';

const startScreens = {
  '': startUnbranded,
  theme1: startTheme1,
  theme2: startTheme2,
  theme3: startTheme3,
  theme4: startTheme4,
};

const AppBuilderPage = ({
  theme,
  setTheme,
  PageSubHeading,
  HeroHeadline,
  HeroSubHeadline,
  HeroDescription,
}) => {
  useEffect(() => {
    Object.keys(startScreens).forEach(key => {
      new Image().src = startScreens[key];
    });
  }, []);

  return (
    <div className="app-builder">
      <Hero
        HeroHeadline={HeroHeadline}
        HeroSubHeadline={HeroSubHeadline}
        HeroDescription={HeroDescription}
      />
      <Container className="container__center-content">
        <div className={styles.content}>
          <div className={styles.themePickerWrap}>
            <PageSubHeading />
            <ThemePicker initialValue={theme} changeHandler={setTheme} />
          </div>

          <div className={styles.phoneWrap}>
            <PhoneInterface position="right">
              <BackgroundImage
                src={startScreens[theme]}
                alt={`${theme} example interface`}
                className={styles.phoneImage}
              >
                {theme && (
                  <Link
                    to={config.tourFlow.appBuilder.next}
                    className={styles.imageLink}
                  />
                )}
              </BackgroundImage>
            </PhoneInterface>
          </div>
        </div>
      </Container>

      <PageNav
        prevLink={config.tourFlow.appBuilder.back}
        nextLink={config.tourFlow.appBuilder.next}
        bg="purple"
        nextDisabled={!theme}
      />
    </div>
  );
};

AppBuilderPage.propTypes = {
  theme: PropTypes.string.isRequired,
  setTheme: PropTypes.func.isRequired,
  PageSubHeading: PropTypes.func,
  HeroHeadline: PropTypes.func,
  HeroSubHeadline: PropTypes.func,
  HeroDescription: PropTypes.func,
};

AppBuilderPage.defaultProps = {
  PageSubHeading: () => (
    <SubHeading variant="small">
      {' '}
      Choose from the pre-populated brands for the purpose of the demo. The next
      screen will be an example of what your end users will experience.
    </SubHeading>
  ),
  HeroHeadline: () => (
    <Headline>Build The Experience Your End Users Expect</Headline>
  ),
  HeroSubHeadline: () => (
    <>
      <SubHeadline>
        With a white-labeled API, you can customize the payment experience{' '}
        <br /> with your branding to maintain a trusted and consistent user
        experience.
      </SubHeadline>
    </>
  ),
  HeroDescription: () => null,
};

export default AppBuilderPage;
