import realTime from '../assets/images/payment-types/real-time-payments.svg';
import nextDay from '../assets/images/payment-types/next-day-ach.svg';
import standard from '../assets/images/payment-types/standard-ach.svg';
import sameDay from '../assets/images/payment-types/same-day-ach.svg';

export const paymentTypesOptions = {
  realTimePayments: {
    label: 'Real-Time Payments',
    transferTime: 'Typically Settles Within Seconds*',
    headline:
      'Send real-time payments to bank accounts participating in the RTP® network.',
    description:
      'RTP transfers can be initiated at all hours of the day—no matter if that day falls on a holiday or weekend.',
    link: 'https://www.dwolla.com/features/real-time-payments/',
    logo: realTime,
  },
  sameDayACH: {
    label: 'Same Day ACH',
    transferTime: 'Less Than 1 Business Day',
    headline: 'Make funds available to end users within the same business day.',
    description:
      'A highly adopted transfer speed among businesses using ACH payments is Same Day ACH transfer timing. Same Day ACH transactions can be for debit or credit payments.',
    link: 'https://www.dwolla.com/features/same-day-ach/',
    logo: sameDay,
  },
  nextDayACH: {
    label: 'Next Day ACH',
    transferTime: '1-2 Business Days',
    headline: 'Speed up your debit transfers for next day availability.',
    description:
      'A Next Day ACH transaction refers to the debit portion of a transaction with Dwolla when funds are debited from a bank account and sent to the Dwolla Balance.',
    link: 'https://www.dwolla.com/features/next-day-ach/',
    logo: nextDay,
  },
  standardACH: {
    label: 'Standard ACH',
    transferTime: '3-4 Business Days',
    headline:
      'Move funds in and out of bank accounts, using the standard ACH processing time.',
    description:
      'Add an element of programmability to your payments by automating manual processes.',
    link:
      'https://www.dwolla.com/resources/understanding-payment-transfer-timelines/',
    logo: standard,
  },
};

export default paymentTypesOptions;
