import React from 'react';
import { BrowserRouter as Router, Route } from 'react-router-dom';
import classNames from 'classnames';

import HomePage from './components/organisms/home-page/HomePage';
import FundsFlowsPageConnection from './components/organisms/funds-flows-page/FundsFlowsPageConnection';
import StepOverviewPageConnection from './components/organisms/step-overview-page/StepOverviewPageConnection';
import AppBuilderPageConnection from './components/organisms/app-builder-page/AppBuilderPageConnection';
import ConclusionPageConnection from './components/organisms/conclusion-page/ConclusionPageConnection';

import CalculatorFundsFlowsPageConnection from './components/organisms/calculator-pages/CalculatorFundsFlowsPageConnection';
import CalculatorResultsPageConnection from './components/organisms/calculator-pages/CalculatorResultsPageConnection';

import styles from './App.module.scss';
import MobileGuard from './components/molecules/mobile-guard/MobileGuard';
import CreatePageMapperConnection from './utils/CreatePageMapperConnection';
import AttachBankMapperConnection from './utils/AttachBankMapperConnection';
import MoveMoneyMapperConnection from './utils/MoveMoneyMapperConnection';
import RefreshRouteConnection from './utils/RefreshRouteConnection';
import { tourStops, calcStops } from './utils/flow-control/demo-flows';
import { isAppContainedInIFrame } from './utils/iframe';
import config from './config';
import CalculatorPaymentsPageConnection from './components/organisms/calculator-pages/CalculatorPaymentsPageConnection';
import PaymentTypesPageConnection from './components/organisms/payment-types-page/PaymentTypesPageConnection';

function App() {
  const DemoRouter = () => (
    <Router>
      <Route exact path={tourStops.home} component={HomePage} />
      <Route path={tourStops.fundsFlows} component={FundsFlowsPageConnection} />
      <RefreshRouteConnection
        path={tourStops.stepOverview}
        component={StepOverviewPageConnection}
      />
      <RefreshRouteConnection
        path={tourStops.appBuilder}
        component={AppBuilderPageConnection}
      />
      <RefreshRouteConnection
        path={tourStops.createUsers}
        component={CreatePageMapperConnection}
      />
      <RefreshRouteConnection
        path={tourStops.attachBank}
        component={AttachBankMapperConnection}
      />
      <RefreshRouteConnection
        path={tourStops.moveMoney}
        component={MoveMoneyMapperConnection}
      />
      <RefreshRouteConnection
        path={tourStops.paymentTypes}
        component={PaymentTypesPageConnection}
      />
      <Route path={tourStops.conclusion} component={ConclusionPageConnection} />
    </Router>
  );

  const CalculatorRouter = () => (
    <Router>
      <Route
        exact
        path={tourStops.home}
        component={CalculatorFundsFlowsPageConnection}
      />
      <RefreshRouteConnection
        path={calcStops.calcPayments}
        component={CalculatorPaymentsPageConnection}
        redirectPath="/"
      />
      <RefreshRouteConnection
        path={calcStops.calcResults}
        component={CalculatorResultsPageConnection}
        redirectPath="/"
      />
    </Router>
  );

  return (
    <div>
      <div
        className={classNames({
          [styles.router]: true,
          [styles.containedInIFrame]:
            config.isCalculator && isAppContainedInIFrame(),
        })}
      >
        {config.isCalculator ? <CalculatorRouter /> : <DemoRouter />}
      </div>

      {config.mobileGuardEnabled && <MobileGuard />}
    </div>
  );
}

export default App;
