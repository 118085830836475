import { connect } from 'react-redux';
import {
  incrementSubStep,
  decrementSubStep,
  resetAttachBank,
} from '../../../../../redux/modules/attach-bank';
import BankAttachedMicroDepositsPage from './BankAttachedMicroDepositsPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  handleVerifyClicked: incrementSubStep,
  handleBackClicked: decrementSubStep,
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(BankAttachedMicroDepositsPage);
