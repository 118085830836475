import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './VideoLoopWrap.module.scss';
import VideoLoop from './VideoLoop';

const VideoLoopWrap = ({ videoOptions, selectedVideo }) => {
  const items = [];
  const [current, setCurrent] = useState(0);

  Object.keys(videoOptions).forEach((key, index) => {
    items.push(
      <div
        key={[index, 'Video Loop']}
        className={classNames(
          styles.videoLoop,
          styles.animate,
          key === 'facilitate' || key === 'sendReceive' ? styles.resize : ''
        )}
      >
        {videoOptions[key].headline && (
          <div className={styles.sub_headline}>
            {videoOptions[key].headline}
          </div>
        )}
        <VideoLoop
          title={`${videoOptions[key].label} Animation`}
          src={videoOptions[key].video}
        />
      </div>
    );
  });

  useEffect(() => {
    setCurrent(videoOptions[`${selectedVideo}`].key);
  }, [videoOptions, selectedVideo, current]);

  return <div>{items[current]}</div>;
};

VideoLoopWrap.propTypes = {
  videoOptions: PropTypes.objectOf(PropTypes.objectOf(PropTypes.string))
    .isRequired,
  selectedVideo: PropTypes.string.isRequired,
};

export default VideoLoopWrap;
