import React from 'react';
import { Link, useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import { Dropdown, DropdownButton } from 'react-bootstrap';
import styles from './DropUpMenu.module.scss';

const DropUpMenu = ({
  createdCustomer,
  fundingSources,
  isFundsFlowSetByUser,
  fundsFlow,
  offlineMode,
}) => {
  const location = useLocation();

  return (
    <div className={styles.dropup_container}>
      {isFundsFlowSetByUser && (
        <DropdownButton
          align="end"
          title="Menu"
          id="dropdown-menu-align-end"
          drop="up"
          variant="dropup-menu"
          size="sm"
          onClick={e => e.stopPropagation(e)}
        >
          <div className={styles.dropdown}>
            <Dropdown.Item
              eventKey="1"
              active={location.pathname === '/' && true}
              as={Link}
              to="/"
            >
              Home
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="2"
              active={location.pathname === '/funds-flows' && true}
              as={Link}
              to="/funds-flows"
            >
              Funds Flows
            </Dropdown.Item>

            {offlineMode && (
              <Dropdown.Item
                eventKey="3"
                active={location.pathname === '/step-overview' && true}
                as={Link}
                to="/step-overview"
              >
                Overview
              </Dropdown.Item>
            )}

            <Dropdown.Divider />

            <Dropdown.Item
              eventKey="4"
              active={location.pathname === '/create-users' && true}
              as={Link}
              to="/create-users"
            >
              Create Users
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="5"
              active={location.pathname === '/attach-bank' && true}
              as={Link}
              to="/attach-bank"
              disabled={Object.keys(createdCustomer).length === 0 && true}
            >
              Attach Payment Accounts
            </Dropdown.Item>

            <Dropdown.Item
              eventKey="6"
              active={location.pathname === '/move-money' && true}
              as={Link}
              to="/move-money"
              disabled={
                (fundsFlow === 'meToMe' && fundingSources.length < 3) ||
                (fundsFlow !== 'meToMe' && fundingSources.length < 1)
              }
            >
              Initiate Transfers
            </Dropdown.Item>

            <Dropdown.Divider />

            <Dropdown.Item
              eventKey="7"
              active={location.pathname === '/payment-types' && true}
              as={Link}
              to="/payment-types"
            >
              Payment Types
            </Dropdown.Item>

            <Dropdown.Item eventKey="8" as={Link} to="/conclusion">
              Contact Sales
            </Dropdown.Item>
            <Dropdown.Item
              eventKey="9"
              as={Link}
              id="testing"
              onClick={e =>
                !!e &&
                window.Osano.cm.showDrawer('osano-cm-dom-info-dialog-open')
              }
            >
              Privacy Options
            </Dropdown.Item>
          </div>
        </DropdownButton>
      )}
    </div>
  );
};

DropUpMenu.propTypes = {
  createdCustomer: PropTypes.object.isRequired,
  fundingSources: PropTypes.array.isRequired,
  isFundsFlowSetByUser: PropTypes.bool.isRequired,
  fundsFlow: PropTypes.string.isRequired,
  offlineMode: PropTypes.bool.isRequired,
};

export default DropUpMenu;
