import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styles from './VideoLoop.module.scss';

const VideoLoop = ({ src, title }) => {
  useEffect(() => {
    document.getElementById(`VideoLoop-${title}`).currentTime = 0;
  });

  return (
    <video
      id={`VideoLoop-${title}`}
      autoPlay
      loop
      muted
      playsInline
      title={title}
      className={styles.videoLoopComponent}
      key={src}
    >
      <source src={src} type="video/mp4" />
    </video>
  );
};

VideoLoop.propTypes = {
  src: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
};

export default VideoLoop;
