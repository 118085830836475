import React from 'react';

export default {
  createCustomer: {
    name: 'createCustomer',
    steps: [
      {
        selector: '.bubble-phone-section',
        heading: 'Your Interface',
        action: node => node.focus(),
        content:
          'This is what an application integrated with Dwolla\n’s API might look like on the front-end. ',
      },
      {
        selector: '.bubble-code-section',
        heading: 'Dwolla API Layer',
        content:
          'The back-end code represents the API request that the application sends to the Dwolla API.',
      },
      {
        selector: '.bubble-next-button',
        heading: 'Autofill',
        content: (
          <>
            Clicking the autofill button will generate test data.{' '}
            <strong>Click it now to get started.</strong>
          </>
        ),
      },
    ],
  },
  customerCreated: {
    name: 'customerCreated',
    steps: [
      {
        selector: '.bubble-code-section',
        heading: 'API Response',
        content:
          'This code is the response returned by the Dwolla API. On the back end, we have just created a Dwolla customer record and a token that points to that customer record.',
      },
      {
        selector: '.bubble-dashboard-section',
        heading: 'Dwolla Dashboard',
        content:
          'We also provide a dashboard that allows you to manage your app. Clients can log in to this dashboard to check the status of customers, bank accounts, transactions and more. These statuses are also available via API calls and webhooks.',
      },
    ],
  },
};
