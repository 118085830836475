import React from 'react';
import { ReactComponent as LeftHexagon } from '../../../assets/images/conclusion/hexagon-left-corner.svg';
import { ReactComponent as RightHexagon } from '../../../assets/images/conclusion/hexagon-right-corner.svg';
import styles from './Hexagons.module.scss';

const Hexagons = () => {
  return (
    <>
      <LeftHexagon className={styles.leftLogo} />
      <RightHexagon className={styles.rightLogo} />
    </>
  );
};

export default Hexagons;
