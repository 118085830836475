import { connect } from 'react-redux';
import {
  submitPlaidTokenExchange,
  decrementSubStep,
  resetAttachBank,
} from '../../../../../redux/modules/attach-bank';
import CreatePlaidVerifiedBankPage from './CreatePlaidVerifiedBankPage';

const mapStateToProps = state => ({
  theme: state.general.theme,
  bearerToken: state.general.bearerToken,
  customerUrl: state.createCustomer.createdCustomer._links.self.href,
  pending: state.attachBank.pending,
  plaidToken: state.attachBank.plaidToken,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  submitPlaidTokenExchange,
  handleBackClicked: decrementSubStep,
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreatePlaidVerifiedBankPage);
