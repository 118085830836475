import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Container } from 'react-bootstrap';
import { Redirect } from 'react-router-dom';
import PageNav from '../../molecules/page-nav/PageNav';
import Hexagons from '../../molecules/hexagons/Hexagons';
import { Heading, SubHeading } from '../../atoms/typography/Typography';
import HubSpotForm from '../../atoms/hubspot-form/HubSpotForm';
import styles from './ConclusionPage.module.scss';
import config from '../../../config';

const ConclusionPage = ({
  resetData,
  PageHeading,
  PageSubHeading,
  PageIFrame,
  PagePostIFrameContent,
}) => {
  const [redirectToHome, setRedirectToHome] = useState(false);

  const startOverHandler = () => {
    resetData();
    setRedirectToHome(true);
  };

  return (
    <div className={styles.conclusionPage}>
      {redirectToHome ? <Redirect to="/" /> : null}

      <Hexagons />

      <div className={styles.conclusionContainer}>
        <Container className={styles.textContainer}>
          <PageHeading />
          <PageSubHeading />
        </Container>

        <div className={styles.formContainer}>
          <div className={styles.card}>
            <div className={styles.content}>
              <PageIFrame />
              <PagePostIFrameContent />
            </div>
          </div>
        </div>
      </div>

      <PageNav
        prevLink={config.tourFlow.conclusion.back}
        nextText="Start Over"
        nextOnClick={startOverHandler}
        bg="purple"
        showBar={false}
      />
    </div>
  );
};

ConclusionPage.propTypes = {
  resetData: PropTypes.func.isRequired,
  PageHeading: PropTypes.func,
  PageSubHeading: PropTypes.func,
  PageIFrame: PropTypes.func,
  PagePostIFrameContent: PropTypes.func,
};

ConclusionPage.defaultProps = {
  PageHeading: () => (
    <Heading>Give Your Business the Flexibility To Innovate</Heading>
  ),
  PageSubHeading: () => (
    <SubHeading>
      Let us help you build a solution that supports your needs for scale.
    </SubHeading>
  ),
  PageIFrame: () => (
    <HubSpotForm hubSpotFormId="ffe1d6db-31f1-4b33-b118-4c83ccfb10a8" />
  ),
  PagePostIFrameContent: () => <></>,
};

export default ConclusionPage;
