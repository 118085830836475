import { connect } from 'react-redux';
import {
  incrementSubStep,
  resetAttachBank,
} from '../../../../../redux/modules/attach-bank';
import FundingSourcesPage from './FundingSourcesPage';
// eslint-disable-next-line import/no-cycle
import { submitLoadCustomerFundingSources } from '../../../../../redux/modules/funding-sources';

const mapStateToProps = state => ({
  theme: state.general.theme,
  loading: state.fundingSources.loading,
  customerId: state.general.offlineMode
    ? null
    : state.createCustomer.createdCustomer.id,
  fundingSourceUrl:
    state.fundingSources.createdFundingSources[0]._links.self.href,
  fundingSources: state.fundingSources.fundingSources,
  variation: state.attachBank.variation,
  subStep: state.attachBank.subStep,
});

const mapDispatchToProps = {
  submitLoadFundingSources: submitLoadCustomerFundingSources,
  handleAddBankClicked: incrementSubStep,
  resetAttachBank,
};

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(FundingSourcesPage);
