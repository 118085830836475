/* eslint-disable react/jsx-props-no-spreading */
import React, { useRef, useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useFocusOnPageLoadWithLoadingAnimation } from '../../../../../hooks/focus-on-page-load';
import {
  isAmountCoveredByBalance,
  isPopulated,
  isValidDollarAmount,
  areSourceAndDestinationDifferent,
} from '../../../../../utils/validators';
import PhonePage from '../../../phone-page/PhonePage';
import TransferRequestCode from '../../../../molecules/code/TransferRequestCode';
import SendFundsScreen from '../../../phone-screens/3-move-money/SendFundsScreen';
import { themeOptions } from '../../../../../utils/propTypeHelper';
import {
  slowFill,
  slowFillSingle,
  chance,
} from '../../../../../utils/autofill';
import PageNavWithAutoFill from '../../../../molecules/page-nav/PageNavWithAutofill';
import LoadingScreen from '../../../phone-screens/LoadingScreen';
import { filterFundingSources } from '../../../../../utils/funding-source-mapper';
import config from '../../../../../config';

const SendMoneyPage = ({
  theme,
  bearerToken,
  loading,
  pending,
  showAdminHeader,
  headingPreText,
  headingText,
  billAmount,
  sendButtonText,
  fundingSources,
  fundingDestinations,
  defaultDestinationUrl,
  submitLoadFundingSources,
  submitMoveMoney,
  resetMoveMoney,
  subStep,
}) => {
  const [sourceUrl, setSourceUrl] = useState('');
  const [destinationUrl, setDestinationUrl] = useState(defaultDestinationUrl);
  const [amount, setAmount] = useState(`${billAmount}.00`);

  useEffect(() => {
    submitLoadFundingSources();
  }, [submitLoadFundingSources]);

  useEffect(() => {
    setDestinationUrl(defaultDestinationUrl);
  }, [defaultDestinationUrl]);

  const elToFocus = useRef(null);
  useFocusOnPageLoadWithLoadingAnimation(elToFocus, loading);

  const isValidInput =
    !pending &&
    isPopulated(sourceUrl) &&
    isValidDollarAmount(amount) &&
    isAmountCoveredByBalance(amount, sourceUrl, fundingSources) &&
    areSourceAndDestinationDifferent(sourceUrl, destinationUrl);

  const handleSubmitClicked = e => {
    e.preventDefault();
    submitMoveMoney({ sourceUrl, destinationUrl, amount });
  };

  const handleAutoFill = () => {
    setSourceUrl('');
    setAmount('');

    slowFillSingle(
      chance.pickone(filterFundingSources(fundingSources).map(fs => fs.value)),
      setSourceUrl
    )
      .then(selectedSourceUrl =>
        fundingDestinations.length
          ? slowFillSingle(
              chance.pickone(
                filterFundingSources(fundingDestinations, [
                  selectedSourceUrl,
                ]).map(fs => fs.value)
              ),
              setDestinationUrl
            )
          : ''
      )
      .then(() =>
        slowFill(
          billAmount
            ? `${billAmount}.00`
            : `${chance.integer({ min: 0, max: 999 })}.${chance.integer({
                min: 10,
                max: 99,
              })}`,
          setAmount
        )
      );
  };

  return (
    <PhonePage
      theme={theme}
      stepPercentage={50}
      currentStep={3}
      processing={pending}
      currentSubStep="Initiate Transaction"
      requestCodeComponent={
        <TransferRequestCode
          sourceUrl={sourceUrl}
          destinationUrl={destinationUrl}
          amount={amount}
          bearerToken={bearerToken}
        />
      }
      pageNavComponent={
        <PageNavWithAutoFill
          showReset={!!subStep}
          resetFunc={resetMoveMoney}
          {...{
            prevLink: config.tourFlow.moveMoney.back,
            isValidInput,
            loading,
            pending,
            handleAutoFill,
            handleSubmitClicked,
          }}
        />
      }
    >
      {!!loading && <LoadingScreen />}
      {!loading && (
        <SendFundsScreen
          {...{
            theme,
            isValidInput,
            pending,
            elToFocus,
            showAdminHeader,
            headingPreText,
            headingText,
            sendButtonText,
            fundingSources,
            sourceUrl,
            setSourceUrl,
            fundingDestinations,
            destinationUrl,
            setDestinationUrl,
            amount,
            setAmount,
            handleSubmitClicked,
          }}
        />
      )}
    </PhonePage>
  );
};

SendMoneyPage.propTypes = {
  theme: PropTypes.oneOf(themeOptions).isRequired,
  bearerToken: PropTypes.string.isRequired,
  loading: PropTypes.bool.isRequired,
  pending: PropTypes.bool.isRequired,
  showAdminHeader: PropTypes.bool,
  headingPreText: PropTypes.string.isRequired,
  headingText: PropTypes.string.isRequired,
  billAmount: PropTypes.number,
  sendButtonText: PropTypes.string,
  fundingSources: PropTypes.arrayOf(PropTypes.object).isRequired,
  fundingDestinations: PropTypes.arrayOf(PropTypes.object),
  defaultDestinationUrl: PropTypes.string,
  submitLoadFundingSources: PropTypes.func.isRequired,
  submitMoveMoney: PropTypes.func.isRequired,
  resetMoveMoney: PropTypes.func.isRequired,
  subStep: PropTypes.number.isRequired,
};

SendMoneyPage.defaultProps = {
  showAdminHeader: false,
  billAmount: 0,
  sendButtonText: 'Send',
  fundingDestinations: [],
  defaultDestinationUrl: '',
};

export default SendMoneyPage;
