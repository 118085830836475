import PropTypes from 'prop-types';
import React from 'react';
import styles from './Hero.module.scss';

import {
  Headline,
  SubHeadline,
  Description,
} from '../../atoms/typography/HeroTypography';

const Hero = ({ HeroHeadline, HeroSubHeadline, HeroDescription }) => {
  return (
    <>
      <div className="text-center">
        <div className={styles.hero}>
          <HeroHeadline />
          <HeroSubHeadline />
          <HeroDescription />
        </div>
      </div>
    </>
  );
};

Hero.propTypes = {
  HeroHeadline: PropTypes.func,
  HeroSubHeadline: PropTypes.func,
  HeroDescription: PropTypes.func,
};

Hero.defaultProps = {
  HeroHeadline: () => (
    <Headline>Unlock Your Fund Flows with the Dwolla API</Headline>
  ),
  HeroSubHeadline: () => (
    <>
      <SubHeadline>
        What funds flow(s) is your business looking to automate?
      </SubHeadline>
    </>
  ),
  HeroDescription: () => (
    <>
      <Description>
        Choose a funds flow, then click “Next” to explore API requests.
      </Description>
    </>
  ),
};

export default Hero;
