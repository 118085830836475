import { connect } from 'react-redux';
import DropUpMenu from './DropUpMenu';

const mapStateToProps = state => ({
  createdCustomer: state.createCustomer.createdCustomer,
  fundingSources: state.fundingSources.fundingSources,
  isFundsFlowSetByUser: state.general.isFundsFlowSetByUser,
  fundsFlow: state.general.fundsFlow,
  offlineMode: state.general.offlineMode,
});

export default connect(mapStateToProps)(DropUpMenu);
