import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import styles from './PhoneInterface.module.scss';
import phoneFrame from '../../../assets/images/phone-interface/graphic-iphone-frame-transparent.png';
import PhoneHeader from './PhoneHeader';
import {
  themeOptionsOptional,
  phonePositions,
} from '../../../utils/propTypeHelper';

const PhoneInterface = ({
  children,
  theme,
  position,
  disablePhoneScrolling,
}) => {
  return (
    <div
      data-testid="phoneInterface"
      className={classNames(
        'bubble-phone-section',
        styles.phoneInterfaceComponent,
        styles[position]
      )}
    >
      <div
        id="phoneScreen"
        className={classNames(
          styles.phoneScreen,
          styles[theme],
          styles[disablePhoneScrolling ? 'noScrolling' : '']
        )}
      >
        {theme && <PhoneHeader theme={theme} />}

        {children}
      </div>

      <img src={phoneFrame} alt="" className={styles.phoneImage} />
    </div>
  );
};

PhoneInterface.propTypes = {
  children: PropTypes.node.isRequired,
  theme: PropTypes.oneOf(themeOptionsOptional),
  position: PropTypes.oneOf(phonePositions),
  disablePhoneScrolling: PropTypes.bool,
};

PhoneInterface.defaultProps = {
  theme: '',
  position: 'center',
  disablePhoneScrolling: false,
};

export default PhoneInterface;
