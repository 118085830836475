import PropTypes from 'prop-types';
import PageMapper from './PageMapper';
import fundsFlows from './flow-control/funds-flows';

const componentMap = {
  send: fundsFlows.send.bankSteps,
  receive: fundsFlows.receive.bankSteps,
  facilitate: fundsFlows.facilitate.bankSteps,
  meToMe: fundsFlows.meToMe.bankSteps,
};

const AttachBankMapper = ({ fundsFlow, variation, subStep }) => {
  return PageMapper({
    componentMap,
    fundsFlow,
    variation,
    subStep,
  });
};

AttachBankMapper.propTypes = {
  fundsFlow: PropTypes.string.isRequired,
  variation: PropTypes.string,
  subStep: PropTypes.number.isRequired,
};

AttachBankMapper.defaultProps = {
  variation: null,
};

export default AttachBankMapper;
